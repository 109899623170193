import { Link } from "react-router-dom";

const CustomLink = ({ children, ...props }) => {
  return (
    <Link to={props.to} style={{ textDecoration: "none", color: "#000" }}>
      {children}
    </Link>
  );
};

export default CustomLink;
