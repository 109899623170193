import DashboardIcon from "@mui/icons-material/Dashboard";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Person2Icon from "@mui/icons-material/Person2";
import GroupIcon from "@mui/icons-material/Group";
import LogoutIcon from "@mui/icons-material/Logout";

export const side1 = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
  },
  {
    text: "Employees",
    icon: <GroupIcon />,
    path: "/employees",
  },
  {
    text: "Inbox",
    icon: <InboxIcon />,
    path: "/inbox",
  },
];

export const side2 = [
  {
    text: "Profile",
    icon: <Person2Icon />,
    path: "/profile",
  },
  {
    text: "Logout",
    icon: <LogoutIcon />,
    path: "/logout",
  },
];
