import React from "react";

import { Form, useFormik } from "formik";
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  gender: "Male",
};

const genderOptions = ["Male", "Female"];

const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("First Name is required"),
  last_name: yup
    .string("Enter your last name")
    .required("Last Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const EmployeeForm = (props) => {
  const { modalPayload } = props;
  const isEdit = modalPayload.mode === "edit";

  //   formik
  const formik = useFormik({
    initialValues: isEdit ? modalPayload.data : initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) =>
      modalPayload.onSubmit(
        values,
        { setSubmitting, setErrors },
        modalPayload.data
      ),
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          autoFocus
          margin="dense"
          id="first_name"
          name="first_name"
          label="First Name"
          variant="standard"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
        />
        <TextField
          fullWidth
          autoFocus
          margin="dense"
          id="last_name"
          name="last_name"
          label="Last Name"
          variant="standard"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          helperText={formik.touched.last_name && formik.errors.last_name}
        />
        <TextField
          fullWidth
          autoFocus
          margin="dense"
          id="email"
          name="email"
          label="Email"
          variant="standard"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Select
          fullWidth
          autoFocus
          sx={{ mt: 3, mb: 2 }}
          id="gender"
          name="gender"
          label="Gender"
          variant="standard"
          value={formik.values.gender}
          onChange={formik.handleChange}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
        >
          {genderOptions.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? <CircularProgress size={24} /> : "Continue"}
        </Button>
      </form>
    </div>
  );
};

export default EmployeeForm;
