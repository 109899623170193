import { Box, Container, Grid, useTheme } from "@mui/material";
import { Budget } from "components/dashboard/budget";
import { TasksProgress } from "components/dashboard/tasks-progress";
import { TotalCustomers } from "components/dashboard/total-customers";
import { TotalProfit } from "components/dashboard/total-profit";
import { LatestOrders } from "components/dashboard/latest-orders";
import { LatestProducts } from "components/dashboard/latest-products";
import { Sales } from "components/dashboard/sales";
import { TrafficByDevice } from "components/dashboard/traffic-by-device";

const Dashboard = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
        py={2}
      >
        <Container maxWidth>
          <Grid container spacing={3} sx={{ mb: 2 }}>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <Budget />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <TotalCustomers employees={120} />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <TasksProgress />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <TotalProfit sx={{ height: "100%" }} />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <Sales />
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <TrafficByDevice sx={{ height: "100%" }} />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <LatestOrders />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
