import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { logout, setCredentials } from "../../features/auth/authSlice";
import jwtDecode from "jwt-decode";
import { baseUrl } from "../../constants/api";

export const isExpired = (token) => {
  try {
    const { exp } = jwtDecode(token);
    if (Date.now() >= exp * 1000) {
      return true;
    }
  } catch (err) {
    // todo: handle this exception
    return true;
  }
  return false;
};

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    // let accessToken = window.localStorage?.getItem("access");
    // let tokenValid = false;
    // try {
    //   jwtDecode(accessToken);
    //   tokenValid = true;
    // } catch (err) {
    //   localStorage.removeItem("access");
    // }
    // if (accessToken && tokenValid) {
    //   headers.set("Authorization", `Bearer ${accessToken}`);
    // } else {
    //   headers.delete("Authorization");
    // }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Employees"],
  endpoints: (builder) => ({}),
});
