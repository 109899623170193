import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  useCreateEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetEmployeesQuery,
  useUpdateEmployeeMutation,
} from "../../../features/employees/employeesApiSlice";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Snackbar,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";
import EmployeeForm from "../../../components/forms/EmployeeForm";

const initialCol = [
  {
    id: "id",
    header: "ID",
    accessorKey: "id",
    numeric: true,
  },
  {
    id: "firstName",
    header: "First Name",
    accessorKey: "first_name",
    numeric: false,
    filterFn: "equals",
  },
  {
    id: "lastName",
    header: "Last Name",
    accessorKey: "last_name",
    numeric: false,
    filterFn: "equals",
  },
  {
    id: "email",
    header: "Email",
    accessorKey: "email",
    numeric: false,
    filterFn: "equals",
  },
  {
    id: "gender",
    header: "Gender",
    accessorKey: "gender",
    numeric: false,
    filterFn: "equals",
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialModalState = { edit: false, delete: false };
const initialToast = { open: false, severity: "success", text: "" };

const Employees = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const { data: employees, isLoading } = useGetEmployeesQuery({
    query: "",
    pagination,
  });

  // mutations
  const [createEmployee, createRes] = useCreateEmployeeMutation();
  const [updateEmployee, updateRes] = useUpdateEmployeeMutation();
  const [deleteEmployee, deleteRes] = useDeleteEmployeeMutation();

  // modal
  const [open, setOpen] = useState(initialModalState);
  const [modalPayload, setModalPayload] = useState(null);
  const handleEditModal = (state) => {
    setOpen({ ...initialModalState, edit: state });
  };
  const handleDeleteModal = (state) => {
    setOpen({ ...initialModalState, delete: state });
  };

  // toast
  const [toast, setToast] = useState(initialToast);
  const handleToastClose = () => {
    setToast(initialToast);
  };

  // actions
  const handleDelete = (payload) => {
    deleteEmployee({ id: payload.id });
    setToast({
      open: true,
      severity: "success",
      text: "Employee deleted successfully",
    });
    handleDeleteModal(false);
  };

  const handleCreate = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const data = { ...values };
    createEmployee({ data })
      .then((res) => {
        setSubmitting(false);
        if (res.error) {
          setErrors(res.error.data);
          return;
        }
        handleEditModal(false);
        setToast({ open: true, severity: "success", text: "Employe created" });
      })
      .catch((err) => {
        setSubmitting(false);
        setToast({
          open: true,
          severity: "error",
          text: "Employe could NOT be created",
        });
      });
  };

  const handleEdit = (values, { setSubmitting, setErrors }, initData) => {
    setSubmitting(true);
    const data = { ...values };
    if (data.email === initData.email) delete data.email;
    updateEmployee({ data })
      .then((res) => {
        setSubmitting(false);
        if (res.error) {
          setErrors(res.error.data);
          return;
        }
        handleEditModal(false);
        setToast({ open: true, severity: "success", text: "Employe updated" });
      })
      .catch((err) => {
        setSubmitting(false);
        setToast({
          open: true,
          severity: "error",
          text: "Employe could NOT be updated",
        });
      });
  };

  return (
    <Container maxWidth>
      <Snackbar
        open={toast.open}
        autoHideDuration={5000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.text}
        </Alert>
      </Snackbar>
      {/* Edit Dialog */}
      {open.edit && (
        <Dialog open={open.edit} onClose={() => handleEditModal(false)}>
          <DialogTitle>{modalPayload.title}</DialogTitle>
          <DialogContent>
            <EmployeeForm modalPayload={modalPayload} />
          </DialogContent>
        </Dialog>
      )}
      {/* Delete Dialog */}
      <Dialog
        open={open.delete}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleDeleteModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Are you sure you want to delete this employee?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Deleting employees will remove them from our database permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteModal(false)}>Disagree</Button>
          <Button onClick={() => handleDelete(modalPayload)}>Agree</Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="outlined"
        onClick={() => {
          setModalPayload({
            mode: "create",
            onSubmit: handleCreate,
            title: "Create Employee",
          });
          handleEditModal(true);
        }}
        sx={{ mb: 2 }}
      >
        Create Employee
      </Button>
      <MaterialReactTable
        columns={initialCol}
        data={employees?.results ?? []}
        enableColumnActions
        enableRowNumbers
        enableColumnFilters
        enablePagination
        enableSorting
        enableBottomToolbar
        enableTopToolbar
        muiTableBodyRowProps={{ hover: false }}
        manualPagination
        rowCount={employees?.count ?? 0}
        onPaginationChange={setPagination}
        state={{ pagination, isLoading }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={(row, index) => (
          <Box>
            <IconButton
              onClick={() => {
                setModalPayload({
                  data: { ...row.cell.row.original },
                  mode: "edit",
                  onSubmit: handleEdit,
                  title: "Edit Employee",
                });
                handleEditModal(true);
              }}
            >
              <EditIcon color="success" />
            </IconButton>
            <IconButton
              onClick={() => {
                setModalPayload({ id: row.cell.row.original.id });
                handleDeleteModal(true);
              }}
            >
              <DeleteIcon sx={{ color: red[500] }} />
            </IconButton>
          </Box>
        )}
      />
    </Container>
  );
};

export default Employees;
