import Dashboard from "./pages/home/Dashboard";
import Employees from "./pages/home/Employees";
import Inbox from "./pages/home/Inbox";
import Logout from "./pages/home/Logout";
import Profile from "./pages/home/Profile";

export const routes = [
  {
    path: "/dashboard",
    exact: true,
    element: <Dashboard />,
  },
  {
    path: "/employees",
    exact: true,
    element: <Employees />,
  },
  {
    path: "/inbox",
    exact: true,
    element: <Inbox />,
  },
  {
    path: "/profile",
    exact: true,
    element: <Profile />,
  },
  {
    path: "/logout",
    exact: true,
    element: <Logout />,
  },
];
