import { apiSlice } from "../../app/api/apiSlice";

export const employeesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: ({ query, pagination }) => ({
        url: `/employees/?page=${pagination.pageIndex + 1}`,
      }),
      transformResponse: (response) => {
        return response;
      },
      providesTags: ["Employees"],
    }),
    getEmployee: builder.query({
      query: ({ id }) => ({
        url: `/employees/${id}`,
      }),
      transformResponse: (response) => {
        return response;
      },
      providesTags: ["Employees"],
    }),
    createEmployee: builder.mutation({
      query: ({ data }) => ({
        url: `/employees/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Employees"],
    }),
    updateEmployee: builder.mutation({
      query: ({ data }) => ({
        url: `/employees/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Employees"],
    }),
    deleteEmployee: builder.mutation({
      query: ({ id }) => ({
        url: `/employees/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Employees"],
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
} = employeesApiSlice;
