import { Container, Typography } from "@mui/material";

const Inbox = () => {
  return (
    <Container maxWidth>
      <Typography variant="h6">We are working on this feature...🔧</Typography>
    </Container>
  );
};

export default Inbox;
