// Styles
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMemo, useState } from "react";
import Nav from "./components/Nav";

function App() {
  const [themeMode, setThemeMode] = useState();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            secondary: "#f2f4f7",
          },
        },
      }),
    [themeMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <Nav />
    </ThemeProvider>
  );
}

export default App;
